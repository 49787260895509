img.user{
    margin-left:700px;
    margin-top: 20px;
    margin-bottom: 6px;
    
}
div.row.user{
    column-gap: 10px;
    align-items: center;
    margin-left: 340px;
    margin-right: 365px;
}
div.col.profile{
    margin-top: 10px;
    border-radius: 8px;
    height: 40px;
    background-color: wheat;
    border-style:ridge;
    border-width: 2px;
    border-color:rgba(254, 132, 26, 0.977)  ;
}
div.prof-row{
    margin-bottom: 40px;
}
h5.prof{
    vertical-align: middle;
    padding-left: 30px;
    line-height: 35px;
    
}
h5.profile{
    margin-left: 700px;
    color: rgba(254, 132, 26, 0.977);
}
h4.mob-tit.mob.pro{
    margin-left: 40px;
}