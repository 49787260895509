/* Resetting some default styles */
body {
    background-color: rgba(246, 210, 111, 0.444) !important;
    padding: 0px 0px;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  input#form1.form-control{
    width: 560px;
  }
 div.col-1.pricewise{
   
   padding-top: 4px;
  
   
 }
 div.container-fluid.catogory{
  padding-top: 2px;
 }

.testimonial-text {
  font-size: 3rem;
  line-height: 1.5;
}

.testimonial-image {
  width: 100%;
  height: 500px;
  margin: 10px;
  padding-right: 20px;
}
h5.viewall{
  color: rgb(252, 118, 0);
}
h6.cat-list:hover{
  color: rgb(252, 118, 0);
  transition-duration: 0.3s;
}
h6.cat-list{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    color: black;
}
.mt-50{
  margin-top: 50px;
}
.mb-50{
  margin-bottom: 50px;
}


.bg-teal-400 { 
  background-color: #26a69a;
}

a{
  text-decoration: none !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
}


.fa {
      color: red;
}
div.card.card-body{
  background-color: rgba(249, 157, 44, 0.175);
}

h3.mb-0.font-weight-semibold{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
}

h3.mb-0.font-weight-semibold{
  color: green;
}
img.phones{
  mix-blend-mode:multiply;
}
.carousel-item.active.container-fluid {
  transition-duration: 1s;
}
.carousel-item{
  transition-timing-function: ease-in-out;
}
div.maindiv{
  padding-top: 20px;
}
div.col-1.logo.btn.float-end{
  
 padding-right: 50px;
}
img.homepage-logo{
  margin-top: 3px;
  margin-left: 80px;
}
h5.viewall{
  font-size: 1rem;
  padding-top: 4px;
}

h5#offcanvas.offcanvas-title.d-none.d-sm-block{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  color: rgb(251, 224, 189);;
}
div#offcanvas.offcanvas.offcanvas-start.w-10{
  background-color: rgba(254, 132, 26, 0.977)  
}
span.ms-1.d-none.d-sm-inline{
  color: black;
}
div.footer{
  height: 50px;
  background-color:  rgba(250, 165, 90, 0.773) ;
  text-align: center;
  
}
h5.copyright{
  padding-top: 15px;
  font-weight: 400;
  color: rgba(252, 230, 209, 0.932);
}
i{
  color: black;
}
ul.dropdown-menu.text-small.shadow{
  background-color:rgb(246, 206, 153);
}
a.col-md-4{
  margin-top: 5px;
}
p.mb-3{
  color: black;
}
button.btn.btn-primary.search-button{
  margin-left: 57px;
}
h6.pw{
  margin-right: 30px;
}
i.fa-solid.fa-bell.fa-xl{
margin-left: 80px;
margin-top: 18px;
}
i.fa-solid.fa-user.fa-xl{
  margin-left: 80px;
  margin-top: 18px;
  }
  i.fa-solid.fa-clock.fa-xl{
    margin-left: 80px;
    margin-top: 18px;
    }
  i.fa-solid.fa-bars.fa-xl{
    margin-top: 18px;
    margin-left: 20px;
  }