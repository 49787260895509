

body {
    background-color: rgba(246, 210, 111, 0.444)!important;
    padding: 50px 550px;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  
  .form-group{
    
    padding:10px 0px; 
  }
  h1{
    text-align: center;
  }
  .log-sign-button{
    margin-top: 10px;
      justify-content: center;
      align-items: center;
      width: 435px;
      
    }
    .logoimg{
      margin-left: 158px;
    }
  :placeholder-shown{
    text-align: center;
  }
  h6{
    padding-top: 5px;
    text-align:right;
    color: rgba(0, 0, 0, 0.662);
  }
  h1,span{
    color: rgb(244, 161, 8);
  }
  .signup{
    text-align: center;
  }
  .signupbox{
    margin-top: 5px;
  }
  .btn-primary{
    background-color:rgb(252, 118, 0)!important;
    border-color: rgb(252, 118, 0)!important;
    transition-duration: 0.3s;
  }
  h2{
    color: rgb(252, 118, 0);
    transition-duration: 0.3s;
  }
  h2:hover{
    color: rgb(254, 173, 0);
  }
  .btn-primary:hover{
    background-color: rgb(254, 173, 0);
    border-color:rgb(243, 197, 32) ;
  }
  h3{
    text-align: center;
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 0.732);
    font-family: 'Kaushan Script';font-size: 22px;
  }
  .logobox{
    margin-bottom: 10px;
  }
  .login{
        text-align: center;
  }
  .search-button{
    height: 36px;
    left: 6px;
  }
  
  img.homepage-logo{
   bottom: 400px;
  
  }
  .container{
    width: 1200px;
  }
  h6{
    text-align: center;
    margin-bottom: 30px;
    color: rgba(0, 0, 0, 0.732);
    font-family: 'Kaushan Script';font-size: 22px;
  }
  #form1{
    width: 600px;
  
  }
  button{
    background-color:rgb(252, 118, 0) ;
  }
  input[type="email" ]::placeholder {
    text-align: left;
}
input[type="password" ]::placeholder {
  text-align: left;
}
input[type="text" ]::placeholder {
  text-align: left;
}
div.log{
  margin-left: 670px;
  margin-top: 20px;
}