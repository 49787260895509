h4.cat-tag{
    margin-left: 90px;
    margin-top: 60px;
    color: black;
}
div.col-3.set.cat{
    margin-left: 52px;
}
div.col-3.set.cat.two{
    margin-top:20px;
}
img.main-img.lap11.tv{
    margin-top: 15px;
}
img.main-img.fri11.fri{
    margin-top: 5px;
    margin-left: 40px;
}