div.container-fluid.not{
    padding-left: 50px;
}
div.row.not{
    margin-top: 10px;
    column-gap: 5px;
    
}
div.col-8.set{
    background-color:  rgba(249, 157, 44, 0.295);
    border-radius: 10px;
    margin-left: 30px;
    border-color: rgba(254, 132, 26, 0.729);
    border-style: solid ;
    border-width: 1px;
   
}
div.col-3.set{
    background-color:  rgba(249, 157, 44, 0.295);
    width: 438px;
    border-radius: 10px;
    margin-left: 8px;
    border-color: rgba(254, 132, 26, 0.729);
    border-style: solid ;
    border-width: 1px;
    
}
div.not-header{
    width: 100%;
    height:50px;
    background-color:  rgba(249, 157, 44, 0.175);
    margin-bottom: 20px;
}
h4.mob-tit.not{
    padding-top: 5px;
}
h5.not-det{
    padding-top: 40px;
}
img.not-img{
    margin-left: 100px;
    mix-blend-mode:multiply;
    padding: 10px 0px;
}